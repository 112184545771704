@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    color: white;
    background: #1b1b1e;
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
}

.nav-header {
    border-top: 3px solid #f33d3d;
    padding-top: .5vh;
}

.nav-item {
    transition: .25s;
    font-size: 2.5vh;
    margin-right: 2vh;
}

.nav-item:hover {
    cursor: pointer;
    transition: .25s;
}

.nav-item:hover > a {
    color: #f33d3d!important;
    transition: .25s;
}

.cart-item {
    background: #f33d3d;
    border-radius: 6px;
    padding: .05vh 3vh .05vh 3vh;
    margin-left: 1.5vh;
    border: 1px solid transparent;
}

.cart-item > a {
    color: white!important;
}

.cart-item:hover {
    background: white!important;
    border: 1px solid #f33d3d;
    transition: .25s;
}

.cart-item:hover > a {
    color: #f33d3d!important;
}


.page-container {
    width: 95%;
    margin-left: 2.5%;
    font-family: 'Montserrat', sans-serif;
}

.home-container {
    height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    align-content: center;
    background: black;
    width: 100%;
    padding: 2%;
    padding-right: 2.75%;
}

.home-largetxt {
    color: white;
    font-size: 7vh;
    font-weight: 700;
    line-height: 1.1142857142857143;
    font-size: clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem);
    margin-top: -7.5vh;
    margin-bottom: 20px;
}

.home-subtext {
    font-weight: 400;
    color: white;
    display: block;
    margin: 0;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0;
    margin-bottom: 30px;
    max-width: 80%;
}

.home-image {
    border-radius: 1vh;
    margin-top: -7.5vh;
    float: right;
}

.home-container {
    display: inline-flex;
}


.home-left {
    width: 60%;
    flex-flow: column;
    align-content: center;
    align-items: center;
    align-self: center;
}

.home-left > * {
    display: block;
}

.page-left {
    width: 50%;
    flex-flow: column;
    align-content: center;
    align-items: center;
    align-self: center;
}

.page-left > * {
    display: block;
}

.page-right {
    width: 50%;
}

.page-image {
    display: flex;
    width: 50%;
    margin: auto;
}

.home-right {
    width: 40%;
}

.large-title {
    color: white;
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 700;
}

.how-it-works-text {
    color: white;
    display: block;
    font-size: 3vh;
}

.section-mt {
    margin-top: 15vh;
}

.ch-color {
    color: #f33d3d!important;
}

.wch-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    text-align: center;
    color: white;
}

.wch-icon {
    background: #f33d3d;
    color: white;
    font-size: 6vh;
    padding: 3vh 4.5vh;
    border-radius: 50%;
    display: block;
    width: fit-content;
}

.wch-icon-first {
    padding: 3vh 4vh;
}

.wch-title {
    font-size: 4vh;
    font-weight: 700;
    margin-top: 1.5vh;
}

.wch-desc {
    font-size: 2.5vh;
}

.chbtn {
    color: white;
    background: #f33d3d;
    font-size: 3.1vh;

    padding: 0.875rem 1rem;
    font-size: 1.1rem;
    line-height: 1.3125;
    letter-spacing: 0;
    text-align: left;

    font-weight: 500;
    transition: .25s;

    border-radius: 8px;
    outline: 0;
    border: 1px solid transparent;
    margin: 0;

    justify-content: center;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.chbtn:hover {
    transition: .25s;
    background: #c23131;
    color: #611818;
}

.chbtn-outline {
    color: #f33d3d;
    border: 1px solid #f33d3d;
    background: transparent;
    font-size: 3.1vh;

    padding: 0.875rem 1rem;
    font-size: 1.1rem;
    line-height: 1.3125;
    letter-spacing: 0;
    text-align: left;

    font-weight: 500;
    transition: .25s;

    border-radius: 8px;
    outline: 0;
    margin: 0;

    justify-content: center;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.chbtn-outline:hover {
    transition: .25s;
    border: 1px solid #c23131;
    color: #c23131;
}

.int-container {
    background: #f33d3d;
    border-radius: 1vh;
    padding: 5vh;
    display: flex;
    align-content: center;
}

.int-text {
    font-size: 5vh;
    color: white;
    font-weight: 500;
}

.int-button {
    background: white;
    color: #f33d3d;
    margin-left: auto;
    font-weight: 500;
}

.footer {
    border-top: 3px solid #f33d3d;
    margin-top: 10vh;
    padding-top: 5vh;
    padding-bottom: 15vh;
    color: white;
    padding-left: 2.5%;
    background: #1e2024;
    display: flex;
    align-items: flex-start;
}

.footer-image {
    width: max-content;
}

.footer-content {
    margin-left: 5vh;
    display: flex;
}

.footer-content > .footer-content-item:first-of-type {
    margin-left: 0;
}

.footer-content > .footer-content-item {
    max-width: 75vh;
    margin-left: 10vh;
}

.footer-content-item-title {
    color: white;
    font-size: 2.5vh;
    font-weight: 700;
    display: block;
}

.footer-content-item-link {
    color: white;
    font-size: 2.5vh;
    font-weight: 500;
    display: block;
}

.home-book {
    width: 85%;
    margin-left: 5%;
}

#product-price {
    text-decoration: underline;
    color: #f34646;
}

.shop-container {
    margin:0 auto!important;
    background-color: #1e2024;
    width: 92.5%;
    border-radius: 10px;
    margin-top: 2.5vh!important;
    display: flex;
    padding: 2%;
}

.product-title {
    font-weight: 700;
    margin-top: 4vh;
    font-size: 4.5vh;
}

.product-about {
    font-size: 2.5vh;
    display: block;
}

.shop-left {
    width: 25%;
}

.shop-center {
    margin-left: 2.5%;
    width: 38%;
    display: flex;
    align-items: center;
    align-content: center;
}

.shop-right {
    margin-left: 4%;
    width: 30%;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 4vh;
    margin-bottom: 4vh;
}

.cart-btn {
    // background: #f34646;
    // color: white;
    // border: 1px solid transparent;
    // padding: 1.15vh 2vh;
    // width: 100%;
    // font-size: 2.75vh;
    // border-radius: 8px;
    // align-self: flex-end;
    // margin-top: 1vh;

    color: white!important;
    background: #f33d3d;
    font-size: 3.1vh;

    padding: 0.185rem 1rem;
    font-size: 1.1rem;
    line-height: 1.3125;
    letter-spacing: 0;
    text-align: left;

    font-weight: 500;
    transition: .25s;

    border-radius: 8px;
    outline: 0;
    border: 1px solid transparent;
    margin: 0;
    margin-left: 1vh;

    justify-content: center;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.cart-btn:hover > a {
    color: white!important;
}

.cart-btn > .nav-link {
    color: white!important;
}

.reviews-container {
    margin-bottom: 20px;
    margin-top: 4vh;
}

.reviews{
    display:inline;
    margin-right:5px
}

.fa-star{
    color:#777c81;
    display:inline-block
}

.star-checked{
    color:#f34646
}

.mg-40px {
    margin-bottom: 40px;
}

.game-container {
    display: flex;
}

.ch-text {
    color: #f33d3d;
}

.mg-l {
    margin-left: 1vh;
}

.ch-s-title {
    font-weight: 700;
    font-size: 2.5vh;
}

.product-description {
    margin-bottom: 2vh;
    font-size: 2.5vh;
}

.checkout-area {
    margin-top: 2.5vh;
}

.product-price {
    font-size: 5vh;
    color: #f33d3d;
}

.product-options {
    max-height: 15vh;
    overflow-y: scroll;
}

.product-option {
    background: #1b1b1e;
    color: #f34646;
    padding: 1.5vh 2vh;
    border-radius: 6px;
    width: 100%;
    font-size: 2.5vh;
    text-align: center;
    margin-top: 2vh;
}

.product-option-selected {
    border: 1px solid #f34646;
}

.product-img {
    width: 100%;
}

.product-about {
    margin-bottom: 2vh;
}

.ch-status {
    font-size: 2.5vh;
}

.navbar {
    max-height: 100px;
    height: 100px;
    background: black;
}

.available-cheats-container {
    display: flex;
    width: 100%;
    margin-top: 2vh;
}

.available-cheat-category-list {
    width: 40%;
}

.available-cheat-category {
    font-size: 3vh;
    background-color: #1e2024;
    padding: 1vh 2vh;
    border-radius: 10px;
    display: flex;
    margin-bottom: 2vh;
    border: 1.5px solid transparent;
}

.available-cheat-category:hover {
    cursor: pointer;
}

.available-cheat-category:hover .available-cheat-category-content > * {
    color: #f33d3d!important;
    transition: .15s;
}

.selected-cheat-category {
    border: 1.5px solid #f33d3d;
    transition: .5s;
}

.selected-cheat-category .available-cheat-category-content > * {
    color: #f33d3d!important;
    transition: .15s;
}

.available-cheat-category-icon {
    font-size: 6vh;
    color: #f33d3d;
}

.available-cheat-category-content {
    margin-left: 2vh;
    transition: .15s;
}

.available-cheat-category-name {
    font-size: 3vh;
    color: white;
    font-weight: 700;
    transition: .15s;
}

.available-cheat-category-desc {
    font-size: 2.5vh;
    color: white;
    display: block;
    transition: .15s;
}

.available-cheats-content {
    width: 60%;
    margin-left: 2vh;
}

.available-cheat {
    width: 100%;
    background-color: #1e2024;
    padding: 1vh 2vh;
    border-radius: 10px;
    margin-bottom: 2vh;
    opacity: 0;
}

.available-cheats-container {
    height: 55vh;
}

.cart-btn-s {
    color: white!important;
    background: #f33d3d;
    font-size: 3.1vh;

    display: block;
    width: 100%;
    padding: .505rem;
    font-size: 1.15rem;
    line-height: 1.3125;
    letter-spacing: 0;
    text-align: center;

    font-weight: 500;
    transition: .25s;

    border-radius: 8px;
    outline: 0;
    border: 1px solid transparent;
    margin: 0;
    margin-left: 1vh;

    justify-content: center;
    box-sizing: border-box;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

    border-top-left-radius: 0;
    border-top-right-radius: 0;

    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.cart-btn-s:hover {
    transition: .25s;
    background: #c23131;
    color: #611818!important;
}

.ch-s-enc {
    border-radius: 6px;
    padding: .75rem 1rem;
    background: #1b1b1e;
    border: 1px solid #db3f3f;
    color: #db3f3f;
    font-size: 1.025rem;
    margin-left: 1vh;
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
    margin-top: 2.5vh;
}

.cart-enc-icon {
    color: #db3f3f;
}

.product-info-c {
    color: white;
    font-size: 1.25rem;
}

.cart {
    width: 95%;
    margin-left: 2.5%;
    display: inline-flex;
    margin-top: 3vh;
}

.cart-left {
    width: 69%;
}

.cart-right {
    width: 30%;
    margin-left: 1%;
    background: black;
    padding: 2.5vh;
    border-radius: 1.25vh;
}

.select-payment-method-title {
    font-size: 3vh;
    font-weight: 700;
    padding-bottom: 1vh;
}

.terms-box-top {
    border: 1.5px solid #1b1b1e;
    border-radius: 3px;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
}

.select-payment-method-option {
    display: flex;
}

.select-payment-method-data > * {
    display: block;
}

.cart-element {
    background: black;
    border-radius: 1.25vh;
    padding: 2vh 2vh;
    width: 100%;
}

.cart-element-inner {
    border: 1px solid #1a1c1f;
    margin: 1.25vh;
    padding: 1.75vh;
    margin-top: 2.5vh;
    border-radius: 1.25vh;
}

.cart-title {
    font-size: 3vh;
    font-weight: 700;
    padding-bottom: 1vh;
}


.cart-item-c {
    display: inline-flex;
    width: 98.5%;
}

.cart-item-left {
    width: 94.5%;
}

.cart-item-left > * {
    display: block;
}

.cart-item-right {
    float: right;
    width: 5%;
    display: flex;
    align-content: center;
    align-items: center;
}

.cart-item-remove {
    width: 100%;
    text-align: center;
}

.cart-item-title {
    font-weight: 700;
}

.select-payment-method-option-img {
    min-width: 10.5vh;
    background: #1a1c1f;
    padding: 2vh;
    border-radius: 1.25vh;
    border: 2px solid transparent;
}

.select-payment-method-option-img:hover {
    cursor: pointer;
}

.select-payment-method-option-subtitle {
    color:#777c81;
    font-size:14px;
}

.selected-payment-method > img {
    border: 2px solid #f34646;
}

.select-payment-method-data {
    margin-left: 2vh;
}

.select-payment-method-options {
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
}

.form-check-input {
    background: #1a1c1f;
}

.form-check-input:checked {
    background: #f34646;
    border-color: #f34646;
}

.mt-3 {
    margin-top: 2vh!important;
}

.checkout-input {
    background: #1a1c1f;
    padding: 1.5vh 2vh;
    margin-top: 1vh;
    border-radius: 1.25vh;
    border: none;
    color: white;
}

.cart-element-label {
    display: block;
}

.cart-title > i, .select-payment-method-title > i {
    color: #f34646;
}

input {
    outline: 0 none;
}

.ch-status-value {
    color: green;
    font-weight: 700;
}

.download-input {
    padding: 2.25vh 2vh;
    font-size: 2.5vh;
    width: 75%;
}

.chbtn-mt {
    margin-top: 5vh;
}

.contact-method {
    border: 1px solid #1a1c1f;
    padding: 0 3vh;
    display: inline-flex;
    width: 100%;
    margin-bottom: 3vh;
    border-radius: 8px;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    transition: .25s;
}

.contact-method:hover {
    transition: .25s;
    border: 1px solid #f33d3d;
    cursor: pointer;
}

.contact-method:hover > .contact-method-text > .contact-method-title, .contact-method:hover > .contact-method-icon {
    transition: .25s;
    color: #f33d3d;
}

.contact-method-icon {
    font-size: 8vh;
    min-width: 9.5vh;
    color: white;
    transition: .25s;
}

.contact-method-text {
    padding-left: 2vh;
    transition: .25s;
}

.contact-method-title {
    font-size: 2.75vh;
    font-weight: 700;
}

.contact-method-text {
    display: flex;
    flex-direction: column;
    padding-top: .5vh;
}

.contact-method-text > * {
    display: block;
}

.modal-content {
    background: #1a1c1f;
}

.modal-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.25);
}

.btn-close {
    color: rgba(255, 255, 255, 0.55)!important;
}

@media only screen and (max-width: 1300px) {
    .cart {
        display: block;
    }

    .cart-left {
        width: 100%
    }

    .cart-item-left {
        width: 90%;
    }

    .cart-item-right {
        width: 10%;
    }

    .cart-right {
        margin-top: 2vh;
        margin-left: 0;
        width: 100%;
    }

    .select-payment-method-option {
        width: 100%;
    }

    .footer-content {
        flex-direction: column
    }

    .footer-content-item {
        display: block;
        width: 100%;
        max-width: 100%!important;
        margin-left: 0!important;
        margin-top: 3vh;
    }
    
    .footer {
        display: block;
    }

    .footer-content {
        margin-left: 1vh;
        width: 95%;
    }

    .home-container {
        display: block;
    }

    .home-left {
        width: 100%;
    }

    .page-left {
        width: 100%;
    }

    .home-largetxt {
        margin-top: 0;
    }

    .home-container {
        height: fit-content;
        max-height: fit-content;
        padding-bottom: 5vh;
    };

    .home-right {
        display: none;
    }

    .page-right {
        display: none;
    }

    .available-cheats-container {
        width: 100%;
        display: block;
        height: fit-content;
    }

    .available-cheat-category-list {
        width: 100%;
    }

    .available-cheats-content {
        width: 100%;
        margin-left: 0;
        height: fit-content;
    }
    
    .int-container {
        display: block;
    }

    .int-button {
        display: block;
        width: 100%;
    }

    .shop-container {
        display: block
    }

    .shop-left {
        width: 100%;
    }

    .shop-center {
        width: 100%;
    }

    .shop-right {
        width: 95%;
        margin-left: 1%;
    }
};

a {
    text-decoration: none;
}